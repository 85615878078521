export enum Right {
    LEGAL_ENTITY_DRAFT_C = 'LEGAL_ENTITY_DRAFT_C',
    LEGAL_ENTITY_APPROVAL = 'LEGAL_ENTITY_APPROVAL',
    LEGAL_ENTITY_DRAFT_U = 'LEGAL_ENTITY_DRAFT_U',
    LEGAL_ENTITY_APPROVED_U = 'LEGAL_ENTITY_APPROVED_U',
    REQUEST_LEGAL_ENTITY_APPROVED_U = 'REQUEST_LEGAL_ENTITY_APPROVED_U',

    LICENCE_CONTRACT_ACTIVE_U = 'LICENCE_CONTRACT_ACTIVE_U',
    LICENCE_CONTRACT_DRAFT_C = 'LICENCE_CONTRACT_DRAFT_C',
    LICENCE_CONTRACT_DRAFT_U = 'LICENCE_CONTRACT_DRAFT_U',
    REQUEST_LICENCE_CONTRACT_APPROVAL = 'REQUEST_LICENCE_CONTRACT_APPROVAL',
    LICENCE_CONTRACT_CHANGE_STATUS = 'LICENCE_CONTRACT_CHANGE_STATUS',
    LICENCE_CONTRACT_TRANSFERNOTE_R = 'LICENCE_CONTRACT_TRANSFERNOTE_R',
    LICENCE_CONTRACT_FEE_R = 'LICENCE_CONTRACT_FEE_R',

    LICENCE_CONTRACT_DRAFT_EXTENSION_U = 'LICENCE_CONTRACT_DRAFT_EXTENSION_U',
    LICENCE_CONTRACT_DRAFT_CONTACT_U = 'LICENCE_CONTRACT_DRAFT_CONTACT_U',
    LICENCE_CONTRACT_DRAFT_FEE_U = 'LICENCE_CONTRACT_DRAFT_FEE_U',
    LICENCE_CONTRACT_ACTIVE_EXTENSION_U = 'LICENCE_CONTRACT_ACTIVE_EXTENSION_U',
    LICENCE_CONTRACT_ACTIVE_CONTACT_U = 'LICENCE_CONTRACT_ACTIVE_CONTACT_U',
    LICENCE_CONTRACT_ACTIVE_FEE_U = 'LICENCE_CONTRACT_ACTIVE_FEE_U',

    ACADEMY_INFO_R = 'ACADEMY_INFO_R',
    GG_INFO_R = 'GG_INFO_R',
    GG_AND_ACADEMY_INFO_U = 'GG_AND_ACADEMY_INFO_U',

    LICENCE_AREA_DELETE = 'LICENCE_AREA_DELETE',
    LICENCE_AREA_DOWNLOAD = 'LICENCE_AREA_DOWNLOAD',
    LICENCE_AREA_UPLOAD = 'LICENCE_AREA_UPLOAD',

    SHOP_U = 'SHOP_U',
    SHOP_C = 'SHOP_C',
    SHOP_MOVE_BETWEEN_LICENCE_AREAS = 'SHOP_MOVE_BETWEEN_LICENCE_AREAS',
    REQUEST_PLANNED_SHOP = 'REQUEST_PLANNED_SHOP',

    PROPERTY_LOUNGE_C = 'PROPERTY_LOUNGE_C',
    PROPERTY_LOUNGE_U = 'PROPERTY_LOUNGE_U',
}
